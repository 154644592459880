import React from 'react'
import Typography from '@material-ui/core/Typography'

import Header from '../../../components/sections/Header'
import Text from '../../../components/sections/Text'
import Film from '../../../components/sections/Film'
import Podcast from '../../../components/sections/Podcast'
import Essay from '../../../components/sections/Essay'
import Soundtrack from '../../../components/sections/Soundtrack'
import Gallery from '../../../components/sections/Gallery'
import Footer from '../../../components/sections/Footer'

import { LinkTab } from '../../../components/Link'

import background from '../../../assets/images/pauline-curnier-jardin/background.png'
import still from '../../../assets/images/pauline-curnier-jardin/still.png'


import img1 from '../../../assets/images/pauline-curnier-jardin/1.png' 
import img2 from '../../../assets/images/pauline-curnier-jardin/2.png' 
import img3 from '../../../assets/images/pauline-curnier-jardin/3.png' 
import { PinDropSharp } from '@material-ui/icons'

const slides = [
  {
    label: 'O',
    image: img1
  },
  {
    label: 'T',
    image: img2
  },
  {
    label: 'D',
    image: img3
  },
];

export default function Album(props) {

  return (
    <>
      <Header
        name='Pauline Curnier-Jardin'
        title='Explosion Ma Baby'
        date='13 August - 3 September 2021'
      />

      <Text>
        <Typography variant='subtitle1'
          paragraph
        >
It’s August. Feel the suffocating heat of the sun penetrating your skin. All around you, an abundance of flesh is spinning. Thousands of men offer up the naked bodies of baby boys to the angelic icon of San Sebastian. Screams, colours, chants and explosions. Money-garlands. Imagine no women except me. Wait, yes, behind us women are following with devotion, all dressed up in well pressed clothes and their stocking feet.
        </Typography>
        <Typography variant='subtitle1'
        paragraph
        >
Now, come back here. Imagine how badly I fell in love with this. I desperately want to be part of it. To be there. I wanted to belong. But I know that I can’t. And so I try to capture it on film. I go there and film it every year, over and over, again and again. One day I will tell the story of a poor and sterile man who wants to replace San Sebastian. But more summers will have to pass before our hero will appear.         </Typography>
         <Typography variant='subtitle1'
        >
* Please play it loud or with headphones.
        </Typography>
      </Text>

      <Film 
        url="585985594?h=ec84693dd4"
        title="Pauline Curnier-Jardin, Explosion Ma Baby (2016) 9 min."
        titleShort="Pauline Curnier-Jardin - Explosion Ma Baby"
      />

      <Essay 
        title="René Girard"
        subtitle="The Sacrificial Crisis"
       url="/articles/rene-girard-the-sacrificial-crisis"
      >
      <Typography
        paragraph variant='subtitle1'
      >
Almost every society has festivals that have retained a ritualistic character over the centuries. Of particular interest to the modern inquirer are observances involving the deliberate violation of established laws; for example, celebrations in which sexual promiscuity is not only tolerated but prescribed or in which incest becomes the required practice.
      </Typography>
      <Typography
        paragraph variant='subtitle1'
      >
Such violations must be viewed in their broadest context: that of the overall elimination of differences. Family and social hierarchies are temporarily suppressed or inverted; children no longer respect their parents, servants their masters, vassals their lords.
      </Typography>
      </Essay>

      <Soundtrack 
        title="The Persistence of Dionysus"
        subtitle="Lilly Markaki in conversation with Felice Moramarco"
        caption=""
        image={still}
        color='#fff'
        url="https://soundcloud.com/felice-moramarco-726293220/lilly-markaki-in-conversation-with-felice-moramarco"
      />

      <Gallery 
        slides={slides}
        title='Festival of San Sebastiano, Palazzolo Acreide, Italy, 1948. Courtesy Basilica di San Paolo and Salvatore Tinè.'
      />
      
      <Footer />

    </>
  );
}
