import React from 'react'

import Layout from '../../layouts/Artist'
import Main from '../../views/artists/pauline-curnier-jardin'

import background from '../../assets/images/pauline-curnier-jardin/background.png'


const Default = () => {

  const mainView = (
    <Layout background={background}>
      <Main />
    </Layout>
  )

  return mainView
}

export default Default
